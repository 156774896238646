import { GetApplicationsResponse } from '@backend/students-api/routes/applications/GET/types'
import { ApiStudentRequest, request } from '../utils/request'
import { useAuthQuery } from './auth'

export type RawListApplicationsResponse = GetApplicationsResponse

// TODO SEM-3661 remove DOCUMENTS_REQUESTED_IN_* when is removed from applications-types-lib
export enum ApplicationState {
  DRAFT = 'DRAFT',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  READY_FOR_SCREENING = 'READY_FOR_SCREENING',
  DOCUMENTS_REQUESTED_IN_SCREENING = 'DOCUMENTS_REQUESTED_IN_SCREENING',
  DEFERRAL_REQUESTED_IN_SCREENING = 'DEFERRAL_REQUESTED_IN_SCREENING',
  READY_FOR_ASSESSMENT = 'READY_FOR_ASSESSMENT',
  DOCUMENTS_REQUESTED_IN_ASSESSMENT = 'DOCUMENTS_REQUESTED_IN_ASSESSMENT',
  DEFERRAL_REQUESTED_IN_ASSESSMENT = 'DEFERRAL_REQUESTED_IN_ASSESSMENT',
  INITIAL_OFFER = 'INITIAL_OFFER',
  DEFERRAL_REQUESTED_IN_INITIAL_OFFER = 'DEFERRAL_REQUESTED_IN_INITIAL_OFFER',
  DECLINED = 'DECLINED',
  WAITLISTED = 'WAITLISTED',
  FINAL_OFFER_ISSUED = 'FINAL_OFFER_ISSUED',
}

export function useListApplications() {
  const { isLoading, data } = useAuthQuery(
    ['app-list'],
    async () => {
      return await request<RawListApplicationsResponse>(
        new ApiStudentRequest('/applications?page[size]=500', {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawListApplicationsResponse => res,
        },
      )
    },
    {
      enabled: true, // TODO: Problem with this config in useAuthQuery hook
    },
  )

  return {
    isLoadingApplications: isLoading,
    applications: data?.data || null,
  }
}
